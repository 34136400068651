var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"lg":"12"}},[_c('Breadcrumb',{attrs:{"labels":_vm.labels}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"relative"},[_c('b-tabs',{staticClass:"caption-add table-custom",attrs:{"card":""}},[_c('b-tab',{staticClass:"overview-tab",attrs:{"title":"Basic Info","active":""}},[_c('validation-observer',{ref:"form_rel",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"profile-frm mb-20",staticStyle:{"border-radius":"5px","margin-top":"10px"},on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',{staticClass:"align-center"},[_c('b-col',{attrs:{"lg":"6"}}),_c('b-col',{attrs:{"lg-6":""}},[_c('div',{staticClass:"text-right mb-2"},[(_vm.userData)?[_c('b-button',{staticClass:"btn-df size-18 black",staticStyle:{"box-shadow":"0px 0px 4px rgba(0, 0, 0, 0.25)","margin-right":"10px"},attrs:{"type":"button","variant":"basic"},on:{"click":_vm.cancelEdit}},[_vm._v("Cancel")]),_c('b-button',{staticClass:"btn-df size-18 black",attrs:{"type":"button","variant":"primary"},on:{"click":_vm.saveEdit}},[_vm._v(" Save ")])]:[_c('b-button',{staticClass:"btn-df size-18 black btn-disabled-gray",attrs:{"type":"button","variant":"primary","disabled":""}},[_vm._v(" Edit ")])]],2)])],1),_c('div',{staticClass:"pb-2 mb-10",staticStyle:{"border-top":"1px solid #000000B2"}}),_c('div',{staticClass:"frm-basic size-16 black-child d-block"},[_c('b-row',{staticStyle:{"width":"100%","margin":"0"}},[_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"input-group-label",attrs:{"label":"PCID","label-cols-lg":"4"}},[_c('b-form-input',{attrs:{"placeholder":"PCID","readonly":""},model:{value:(_vm.showID),callback:function ($$v) {_vm.showID=$$v},expression:"showID"}})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"Project Code","label-cols-lg":"4"}},[_c('validation-provider',{attrs:{"name":"Project Code","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Project Code","formatter":_vm.length100},model:{value:(_vm.detail.code),callback:function ($$v) {_vm.$set(_vm.detail, "code", $$v)},expression:"detail.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required",attrs:{"label":"Status","label-cols-lg":"4"}},[_c('validation-provider',{attrs:{"name":"Status","vid":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"title","options":_vm.selectStatus,"placeholder":"Select status"},model:{value:(_vm.statusDetailText),callback:function ($$v) {_vm.statusDetailText=$$v},expression:"statusDetailText"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Date Created","label-cols-lg":"4"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ altInput: true,altFormat: 'd/m/y', dateFormat: 'Y-m-d' },"placeholder":"dd/mm/yy","disabled":""},model:{value:(_vm.detail.created_at),callback:function ($$v) {_vm.$set(_vm.detail, "created_at", $$v)},expression:"detail.created_at"}})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"Project Note","label-cols-lg":"2"}},[_c('validation-provider',{attrs:{"name":"Project Note","vid":"note","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Project Note/Name","formatter":_vm.length100},model:{value:(_vm.detail.note),callback:function ($$v) {_vm.$set(_vm.detail, "note", $$v)},expression:"detail.note"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1)]}}])})],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }